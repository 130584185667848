.countdown svg circle {
  // stroke-dasharray = radius * 2 * Math.PI;
  stroke-dasharray: 50px;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: currentColor;
  fill: none;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: 50px;
  }
}
