@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import './_variables.scss';
@import '~@nappr/nappr-styles/styles.scss';
@import './_html.scss';
@import './animations/';

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  font-family: $mainFont;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

* {
  outline: none;
}

.debug * {
  outline: 1px solid rgba(255, 0, 0, 0.2);
}

.shadow {
  box-shadow: 0 0 0.875rem 0 rgba(41, 48, 66, 0.1);
}

.gradient-green {
  background-color: #00BFB2;
  background-image: linear-gradient(315deg, #00BFB2 0%, #028090 74%);
}

.gradient-red {
  background-color: #08E1AE;
  background-image: linear-gradient(315deg, #08E1AE 0%, #98DE5B 74%);
}

.loading {
  -webkit-animation: loading 2s infinite linear;
  animation: loading 2s infinite linear;
}

.pill {
  @extend .py6;
  @extend .px12;
  @extend .is-inline-block;

  border-radius: 4px;
}

.toolbar {
  max-width: 48px;
  min-width: 48px;
  width: 48px;
}

.controls {
  @extend .flex-columns;
  @extend .flex-between;
  @extend .items-center;
  @extend .mx24;
  @extend .my12;
}

.card {
  @extend .shadow;
  @extend .is-full-width;
  @extend .my6;
  @extend .p12;

  border-radius: 8px;
}

#root {
  position: relative;
  background: transparent;
  overflow: hidden;
  min-height: 100%;
  min-width: 100%;
}

#application-container {
  overflow-y: auto;
}

#application-page {
  position: relative;
}
