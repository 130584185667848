input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin-left: 6px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:disabled {
  box-shadow: none;
}
