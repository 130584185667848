/* NAPPR-STYLES */
$body-width: 100% !default;
$body-font-size: 16px !default;
$default-padding: 12px !default;

$FontSizes: (8, 10, 11, 12, 14, 16, 18, 20, 24, 32, 44, 48, 56) !default;
$MarginPaddingSizes: (0, 3, 6, 12, 15, 24, 32, 48, 78) !default;

/* FONTS */
$mainFont: 'Open Sans', sans-serif;
$inputFont: helvetica, arial, sans-serif !default;
